// import { usePositions } from "api/position";
import PageTitle from "components/PageTitle";
import PositionBox from "components/PositionBox";

const Positions = () => {
  // const { positions } = usePositions();

  return (
    <div className="bg-dark-900" id="positions">
      <section
        className="py-36 px-2 sm:px-10 justify-center flex flex-col bg-white items-center rounded-xl sm:rounded-[40px]"
        id="#positions"
      >
        <div
          data-aos="fade-in"
          data-aos-delay="50"
          className="w-full max-w-6xl text-dark-400"
        >
          <PageTitle
            title="carreers"
            styleClass="text-dark-400"
            dashClass="bg-dark-400"
          />
          <div className="grid xl:grid-cols-2 xl:gap-60">
            <div>
              <h2 className="text-center sm:text-left text-dark-900">
                Want to build something deftly with us?
              </h2>
              <h5 className="my-9 text-center sm:text-left">
                Check our open positions and apply.
              </h5>
            </div>
            <div className="w-full sm:w-fit flex">
              <div className="flex flex-col p-6 rounded-2xl border-[#DDF4EE] border w-full">
                <h5 className="uppercase font-semibold">
                  Open positions
                </h5>
                <div className="flex flex-col">
                  <PositionBox key={1} title="Senior Backend (.Net) Engineer" href="/jobs/senior-dot-net-developer" />
                  <PositionBox key={2} title="Medior Backend (.Net) Engineer" href="/jobs/medior-dot-net-developer" />
                  {/* {positions?.map((position: any) => (
                    <PositionBox
                      key={position.id}
                      title={position.attributes.Title}
                    />
                  ))} */}
                  {/* <p className="flex flex-col">
                    Currently we don’t have any specific positions, but we are
                    always looking for great talent. Feel free to apply at:
                    <a className="text text-lg font-bold" href="mailto:jobs@deftly.tech">jobs@deftly.tech</a>
                  </p> */}
                </div>
              </div>
            </div>
            {/* <div className="text-lg flex flex-col">
              <span>
                Currently we don’t have any specific positions, but we are
                always looking for great talent. Feel free to reach out.
              </span>
              <a
                className={`py-4 px-7 bg-green-500 text-green-900 rounded-[50px] w-56 h-14 flex items-center justify-center cursor-pointer hover:bg-green-300  mt-5 self-center md:self-auto`}
                href="mailto:office@deftly.tech"
              >
                office@deftly.tech
              </a>
            </div> */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Positions;
