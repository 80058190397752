import useWindowSize from "hooks/useWindowSize";
import arrow from "images/arrow.svg";
import { useState } from "react";
import { Link } from "react-router-dom";

type Props = {
  title: string;
  href: string;
};

const PositionBox = ({ title, href }: Props) => {
  const { width } = useWindowSize();
  const isSmallScreen = width && width <= 640;
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <Link
      target="_blank"
      rel="noreferrer"
      to={href}
      className="py-5 last-of-type:pb-0 sm:min-w-[450px] w-full cursor-pointer  flex-row flex justify-between border-b last:border-b-0"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <h4 className="text-green-700">{title}</h4>{" "}
      {isHovered || isSmallScreen ? (
        <img
          className="transition-all ease-in duration-1000"
          src={arrow}
          alt="arrow"
        />
      ) : (
        <div className="w-9" />
      )}
    </Link>
  );
};

export default PositionBox;
